import client from '../client';

const resource = {
  all: (params, token) => client.get(`api/operations`, { params, headers: { Authorization: `Bearer ${token}` } }),
  get: (operation, token) => client.get(`api/operations/${operation}`, { headers: { Authorization: `Bearer ${token}` } }),
  create: (data, token) => client.post(`api/operations`, data, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } }),
  edit: (operation, data, token) => client.put(`api/operations/${operation}/edit`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}` } }),
  cancel: (operation, token) => client.post(`api/operations/${operation}/cancel`, {}, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } }),
  filters: (token) => client.get(`api/operations/filters`, { headers: { Authorization: `Bearer ${token}` } }),
  executionDate: (params, token) => client.get(`api/operations/execution-date`, { params, headers: { Authorization: `Bearer ${token}` } }),
  export: (params, token) => client.get(`api/operations/export`, { params, responseType: 'blob', headers: { Authorization: `Bearer ${token}` } }),
  generateFile: (id, token) => client.get(`api/operations/${id}/pdf`, { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } }),
};

export default resource;