import { DateTime } from 'luxon';
import { LastFridayForMonth } from 'utils/date-utils';
import client from '../client';

const resource = {
  get: (token, idFond) => client.get(`api/funds/${idFond}`, { headers: { Authorization: `Bearer ${token}` } }),
  portfolioAllocation: (token, idFond) => client.get(`api/funds/portfolio-allocation/${idFond}`, { headers: { Authorization: `Bearer ${token}` } }),
  valuationBenchmark: (token, idFond, startDate = false, endDate = false) => {
    if (!startDate && !endDate) {
      startDate = LastFridayForMonth().toISODate();
      endDate   = DateTime.now().minus({ weeks: 1 }).startOf('week').plus({ days: 5 }).toISODate();
    }

    const queryParameters = [`start_date=${startDate}`, `end_date=${endDate}`].join('&');
  
    return client.get(`api/funds/valuation-benchmark/${idFond}?${queryParameters}`, { headers: { Authorization: `Bearer ${token}` } })
  },
  exportValuationBenchmarkAsCSV: (token, idFond, startDate = false, endDate = false, showBoth = false) => {
    if (!startDate && !endDate) {
      startDate = LastFridayForMonth().toISODate();
      endDate   = DateTime.now().minus({ weeks: 1 }).startOf('week').plus({ days: 5 }).toISODate();
    }

    const queryParameters = [`start_date=${startDate}`, `end_date=${endDate}`, `vl_benchmark=${showBoth}`].join('&');

    return client.get(`api/funds/valuation-benchmark/${idFond}/csv?${queryParameters}`, { responseType: 'blob', headers: { Authorization: `Bearer ${token}` } })
  },
};

export default resource;