import { DateTime } from 'luxon';

export const LastFridayForMonth = () => {
    let startDate = DateTime.now().minus({ year: 1 }).endOf('year').startOf('week').plus({ days: 4 });
  
    if (startDate.year !== DateTime.now().minus({ year: 1 }).year) {
        startDate.minus({ days: 7 });
    }
  
    return startDate;
}