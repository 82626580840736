import axios from "axios";

const { REACT_APP_API_URL: baseURL } = process.env;

const client = axios.create({ baseURL });

client
    .interceptors
    .response
    .use(response => response, error => {
        console.log(error.response.status, error.response)
        if (error.response.status === 401 && error.response.config.url !== 'api/login_check') {
            sessionStorage.clear();
            window.location.href = '/';
        } else {
            return Promise.reject(error)
        }
    });

export default client;
