import { createGlobalStyle } from 'styled-components';
import { fonts } from "../style/fonts";
import { colors } from './variables';

export const GlobalStyle = createGlobalStyle`
  ${colors};
  ${fonts};
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  /* -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  } */
}
body {
  }
  ul {
  text-decoration: none;
  list-style-type: none;
}
  a {
  text-decoration: none;
  color: inherit;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#rc-anchor-container {
}
.highcharts-credits {
  display: none;
}
.highcharts-container {
  height: fit-content;
}
`;
