import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from 'context/AppContext';
import { ServiceWorkerProvider } from 'context/ServiceWorkerContext';
import { App } from './App';
import * as Styled from './assets/style/globalStyle';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ServiceWorkerProvider>
      <AppProvider>
        <Router>
          <Styled.GlobalStyle />
          <App />
        </Router>
      </AppProvider>
    </ServiceWorkerProvider>
  </React.StrictMode>
);
