import { Loader } from 'components/loader';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
const LogIn = lazy(() => import('pages/connexion/components/log-in'));
const ResetPassword = lazy(() => import('pages/connexion/components/reset-password'));
const CheckEmail = lazy(() => import('pages/connexion/components/check-email'));
const NewPassword = lazy(() => import('pages/connexion/components/new-password'));
const AccountActivation = lazy(() => import('pages/connexion/components/account-activation'))
const Logout = lazy(() => import('pages/connexion/components/log-out'));
const Fonds = lazy(() => import('pages/fonds'));
const MaSituation = lazy(() => import('pages/ma-situation'));
const MesOperations = lazy(() => import('pages/mes-operations'));
const Profile = lazy(() => import('pages/profile'));
const Publication = lazy(() => import('pages/publication'));
const SouscriptionRachat = lazy(() => import('pages/souscription-rachat'));
const Succes = lazy(() => import('pages/succes'));
const ErrorPage = lazy(() => import('pages/error'));
const Detail = lazy(() => import('pages/detail-fond'));

export const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <MaSituation />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/profile-modification" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/les-fonds/:fund" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Detail />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/les-fonds" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Fonds />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/mes-operations" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <MesOperations />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/mes-operations/succes" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Succes />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/mes-documents" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Publication />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/mes-operations/souscription-rachat" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <SouscriptionRachat />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/login" element={
                <Suspense fallback={<Loader />}>
                    <PublicRoute>
                        <LogIn />
                    </PublicRoute>
                </Suspense>
            } />
            <Route path="/logout" element={
                <Suspense fallback={<Loader />}>
                    <PrivateRoute>
                        <Logout />
                    </PrivateRoute>
                </Suspense>
            } />
            <Route path="/account-activation/:token" element={
                <Suspense fallback={<Loader />}>
                    <PublicRoute>
                        <AccountActivation />
                    </PublicRoute>
                </Suspense>
            } />
            <Route path="/reset-password" element={
                <Suspense fallback={<Loader />}>
                    <PublicRoute>
                        <ResetPassword />
                    </PublicRoute>
                </Suspense>
            } />
            <Route path="/reset-password/check-email" element={
                <Suspense fallback={<Loader />}>
                    <PublicRoute>
                        <CheckEmail />
                    </PublicRoute>
                </Suspense>
            } />
            <Route path="/reset-password/reset/:token" element={
                <Suspense fallback={<Loader />}>
                    <PublicRoute>
                        <NewPassword />
                    </PublicRoute>
                </Suspense>
            } />
            <Route path="/500" element={
                <Suspense fallback={<Loader />}>
                    <ErrorPage errorType="500" />
                </Suspense>
            } />
            <Route path="/*" element={
                <Suspense fallback={<Loader />}>
                    <ErrorPage />
                </Suspense>
            } />
        </Routes>
    );
};
