import client from '../client';

const resource = {
  get: (token) => client.get(`api/accounts`, { headers: { Authorization: `Bearer ${token}` } }),
  subAccounts: (account, token) => client.get(`api/accounts/${account}/sub-accounts`, { headers: { Authorization: `Bearer ${token}` } }),
  banks: (account, token) => client.get(`api/accounts/${account}/banks`, { headers: { Authorization: `Bearer ${token}` } }),
  cashAccounts: (account, bank, token) => client.get(`api/accounts/${account}/${bank}/bank-accounts`, { headers: { Authorization: `Bearer ${token}` } }),
  filter: (params, token) => client.get(`api/accounts/filter`, { params, headers: { Authorization: `Bearer ${token}` } }),
};

export default resource;