import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from 'context/AppContext';

export const PrivateRoute = ({ children }) => {
    const appContext = useContext(AppContext);
    const location   = useLocation();

    if (! appContext?.token) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return children;
}