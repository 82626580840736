import auth from "./services/auth";
import situation from "./services/situation";
import operation from "./services/operation";
import detailFond from "./services/detail-fond";
import document from "./services/document";
import newsletter from "./services/newsletter";
import advisor from "./services/advisor";
import fund from "./services/fund";
import account from "./services/account";
import profile from "./services/profile";
import notification from "./services/notification";
import pushSubscription from "./services/push-subscription";

export const API = {
  auth,
  situation,
  operation,
  detailFond,
  document,
  newsletter,
  advisor,
  fund,
  account,
  profile,
  notification,
  pushSubscription
}