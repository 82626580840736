import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from 'context/AppContext';
import { useServiceWorker } from 'context/ServiceWorkerContext';
import { API } from 'api';
import { change } from 'actions/connectivity';
import { push } from 'actions/notification';
import { AppRouter } from 'routers/AppRouter';
import { isIOS } from 'utils/functions';

export const App = () => {
  const navigate             = useNavigate();
  const appContext           = useContext(AppContext);
  const serviceWorkerContext = useServiceWorker();

  const handleConnectivity = () => {
    appContext.dispatch(change({ connected: navigator.onLine }));
  }

  let timer;

  const handleNotification = () => {
    const notification = {
      topic: 'session',
      tag: 'remind',
      data: {
        seconds: 60,
      },
      actions: {
        signOut: handleSignOut,
        keepMe: handleKeepMe
      }
    };

    appContext.dispatch(push({ notification }));
  }

  const handleSignOut = (event) => {
    event.stopPropagation();

    navigate('/logout');
  }

  const handleKeepMe = (event) => {
    event.stopPropagation();

    handleTimer();
  }

  const handleTimer = () => {
    clearTimeout(timer);

    if (! appContext.token || ! appContext.connected) {
      return;
    }

    timer = setTimeout(handleNotification, 60000 * 30);
  }

  useEffect(() => {
    handleTimer();

    if (appContext.token && appContext.connected) {
      askPermission();
    }
  }, [appContext.token]);

  // useEffect(() => {
  //   if (serviceWorkerContext.assetsCached) {
  //     const notification = {
  //       topic: 'update',
  //       tag: 'remind',
  //       actions: {
  //         update: serviceWorkerContext.updateAssets,
  //       }
  //     };

  //     appContext.dispatch(push({ notification }));
  //   }
  // }, [serviceWorkerContext]);

  const askPermission = async () => {
    if (isIOS()) {
      return;
    }

    if (navigator.onLine && appContext.token) {
      const permission = await Notification.requestPermission();
  
      if (permission === 'granted') {
        await subscribe();
      }
    }
  }

  const getPublicKey = async () => {
    const response = await API.pushSubscription.getPublicKey(appContext?.token);
  
    if (response?.status !== 200) {
      return null;
    }
  
    return response.data.key;
  }
  
  const saveSubscription = async (subscription) => {
    const form = new FormData();
  
    form.append('endpoint', subscription.endpoint);
    form.append('keys', JSON.stringify(subscription.keys));
  
    API.pushSubscription
      .register(appContext?.token, form)
      .then(response => {
        console.log("Subscribed!")
      })
  }
  
  const subscribe = async () => {
    const registration = await navigator.serviceWorker.ready;
  
    let subscription = await registration.pushManager.getSubscription();
  
    if (!subscription) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: await getPublicKey()
      })
    }
  
    await saveSubscription(JSON.parse(JSON.stringify(subscription)));
  }
  

  useEffect(() => {
    window.addEventListener('online', handleConnectivity);
    window.addEventListener('offline', handleConnectivity);
    window.addEventListener('click', handleTimer);
  
    return () => {
      window.removeEventListener('online', handleConnectivity);
      window.removeEventListener('offline', handleConnectivity);
      window.removeEventListener('click', handleTimer);
    }
  }, []);

  return (<AppRouter />);
};
