import React from "react";
import * as serviceWorker from "serviceWorkerRegistration";

const ServiceWorkerContext = React.createContext();

function ServiceWorkerProvider(props) {
    const [assetsUpdateReady, setAssetsUpdateReady] = React.useState(false);
    const [assetsCached, setAssetsCached] = React.useState(false);

    const value = React.useMemo(
        () => ({
            assetsUpdateReady,
            assetsCached,
            // Call when the user confirm update of application and reload page
            updateAssets: () => {
                window.location.reload();
            },
        }),
        [assetsUpdateReady, assetsCached]
    );

    // Once on component mounted subscribe to Update and Succes events in
    // CRA's service worker wrapper
    React.useEffect(() => {
        serviceWorker.register({
            onUpdate: (registration) => {
                const waitingServiceWorker = registration.waiting;

                if (waitingServiceWorker) {
                    waitingServiceWorker.addEventListener("statechange", (event) => {
                        if (event.target.state === "activated") {
                            setAssetsCached(true);
                        }
                    });

                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                }

                setAssetsUpdateReady(true);
            },
            onSuccess: () => {
                setAssetsCached(true);
            },
        });
    }, []);

    return <ServiceWorkerContext.Provider value={value} {...props} />;
}

function useServiceWorker() {
    const context = React.useContext(ServiceWorkerContext);

    if (!context) {
        throw new Error("useServiceWorker must be used within a ServiceWorkerProvider");
    }

    return context;
}

export { ServiceWorkerProvider, useServiceWorker };
