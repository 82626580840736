import { css } from 'styled-components';

import BarlowSemiBoldWoff2 from '../fonts/barlow/barlow-semi-bold.woff2';
import BarlowBoldWoff2 from '../fonts/barlow/barlow-bold.woff2';
import BarlowMediumWoff2 from '../fonts/barlow/barlow-medium.woff2';
import BarlowRegularWoff2 from '../fonts/barlow/barlow-regular.woff2';
import BarlowSemiBoldWoff from '../fonts/barlow/barlow-semi-bold.woff';
import BarlowBoldWoff from '../fonts/barlow/barlow-bold.woff';
import BarlowMediumWoff from '../fonts/barlow/barlow-medium.woff';
import BarlowRegularWoff from '../fonts/barlow/barlow-regular.woff';
import BarlowSemiBoldTtf from '../fonts/barlow/barlow-semi-bold.ttf';
import BarlowBoldTtf from '../fonts/barlow/barlow-bold.ttf';
import BarlowMediumTtf from '../fonts/barlow/barlow-medium.ttf';
import BarlowRegularTtf from '../fonts/barlow/barlow-regular.ttf';

import BarlowCondensedBoldWoff2 from '../fonts/barlow-condensed/barlow-condensed-bold.woff2';
import BarlowCondensedLightWoff2 from '../fonts/barlow-condensed/barlow-condensed-light.woff2';
import BarlowCondensedMediumWoff2 from '../fonts/barlow-condensed/barlow-condensed-medium.woff2';
import BarlowCondensedRegularWoff2 from '../fonts/barlow-condensed/barlow-condensed-regular.woff2';
import BarlowCondensedBoldWoff from '../fonts/barlow-condensed/barlow-condensed-bold.woff';
import BarlowCondensedLightWoff from '../fonts/barlow-condensed/barlow-condensed-light.woff';
import BarlowCondensedMediumWoff from '../fonts/barlow-condensed/barlow-condensed-medium.woff';
import BarlowCondensedRegularWoff from '../fonts/barlow-condensed/barlow-condensed-regular.woff';
import BarlowCondensedBoldTtf from '../fonts/barlow-condensed/barlow-condensed-bold.ttf';
import BarlowCondensedLightTtf from '../fonts/barlow-condensed/barlow-condensed-light.ttf';
import BarlowCondensedMediumTtf from '../fonts/barlow-condensed/barlow-condensed-medium.ttf';
import BarlowCondensedRegularTtf from '../fonts/barlow-condensed/barlow-condensed-regular.ttf';

import FiraSansBoldWoff2 from '../fonts/fira-sans/fira-sans-bold.woff2';
import FiraSansLightWoff2 from '../fonts/fira-sans/fira-sans-light.woff2';
import FiraSansMediumWoff2 from '../fonts/fira-sans/fira-sans-medium.woff2';
import FiraSansRegularWoff2 from '../fonts/fira-sans/fira-sans-regular.woff2';
import FiraSansBoldWoff from '../fonts/fira-sans/fira-sans-bold.woff';
import FiraSansLightWoff from '../fonts/fira-sans/fira-sans-light.woff';
import FiraSansMediumWoff from '../fonts/fira-sans/fira-sans-medium.woff';
import FiraSansRegularWoff from '../fonts/fira-sans/fira-sans-regular.woff';
import FiraSansBoldTtf from '../fonts/fira-sans/fira-sans-bold.ttf';
import FiraSansLightTtf from '../fonts/fira-sans/fira-sans-light.ttf';
import FiraSansMediumTtf from '../fonts/fira-sans/fira-sans-medium.ttf';
import FiraSansRegularTtf from '../fonts/fira-sans/fira-sans-regular.ttf';

import RobotoLightWoff2 from '../fonts/roboto/roboto-light.woff2';
import RobotoMediumWoff2 from '../fonts/roboto/roboto-medium.woff2';
import RobotoRegularWoff2 from '../fonts/roboto/roboto-regular.woff2';
import RobotoBoldWoff2 from '../fonts/roboto/roboto-bold.woff2';
import RobotoLightWoff from '../fonts/roboto/roboto-light.woff';
import RobotoMediumWoff from '../fonts/roboto/roboto-medium.woff';
import RobotoRegularWoff from '../fonts/roboto/roboto-regular.woff';
import RobotoBoldWoff from '../fonts/roboto/roboto-bold.woff';
import RobotoLightTtf from '../fonts/roboto/roboto-light.ttf';
import RobotoMediumTtf from '../fonts/roboto/roboto-medium.ttf';
import RobotoRegularTtf from '../fonts/roboto/roboto-regular.ttf';
import RobotoBoldTtf from '../fonts/roboto/roboto-bold.ttf';

export const fonts = css`
  @font-face {
    font-family: Barlow;
    src: url(${BarlowRegularWoff2}) format('woff2'),
      url(${BarlowRegularWoff}) format('woff'),
      url(${BarlowRegularTtf}) format('ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: Barlow;
    src: url(${BarlowMediumWoff2}) format('woff2'),
      url(${BarlowMediumWoff}) format('woff'),
      url(${BarlowMediumTtf}) format('ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: Barlow;
    src: url(${BarlowSemiBoldWoff2}) format('woff2'),
      url(${BarlowSemiBoldWoff}) format('woff'),
      url(${BarlowSemiBoldTtf}) format('ttf');
    font-weight: 600;
  }

  @font-face {
    font-family: Barlow;
    src: url(${BarlowBoldWoff2}) format('woff2'),
      url(${BarlowBoldWoff}) format('woff'), url(${BarlowBoldTtf}) format('ttf');
    font-weight: 700;
  }

  @font-face {
    font-family: Barlow Condensed;
    src: url(${BarlowCondensedLightWoff2}) format('woff2'),
      url(${BarlowCondensedLightWoff}) format('woff'),
      url(${BarlowCondensedLightTtf}) format('ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: Barlow Condensed;
    src: url(${BarlowCondensedRegularWoff2}) format('woff2'),
      url(${BarlowCondensedRegularWoff}) format('woff'),
      url(${BarlowCondensedRegularTtf}) format('ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: Barlow Condensed;
    src: url(${BarlowCondensedMediumWoff2}) format('woff2'),
      url(${BarlowCondensedMediumWoff}) format('woff'),
      url(${BarlowCondensedMediumTtf}) format('ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: Barlow Condensed;
    src: url(${BarlowCondensedBoldWoff2}) format('woff2'),
      url(${BarlowCondensedBoldWoff}) format('woff'),
      url(${BarlowCondensedBoldTtf}) format('ttf');
    font-weight: 700;
  }

  @font-face {
    font-family: Fira Sans;
    src: url(${FiraSansLightWoff2}) format('woff2'),
      url(${FiraSansLightWoff}) format('woff'),
      url(${FiraSansLightTtf}) format('ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: Fira Sans;
    src: url(${FiraSansRegularWoff2}) format('woff2'),
      url(${FiraSansRegularWoff}) format('woff'),
      url(${FiraSansRegularTtf}) format('ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: Fira Sans;
    src: url(${FiraSansMediumWoff2}) format('woff2'),
      url(${FiraSansMediumWoff}) format('woff'),
      url(${FiraSansMediumTtf}) format('ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: Fira Sans;
    src: url(${FiraSansBoldWoff2}) format('woff2'),
      url(${FiraSansBoldWoff}) format('woff'),
      url(${FiraSansBoldTtf}) format('ttf');
    font-weight: 700;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff'),
      url(${RobotoLightTtf}) format('ttf');
    font-weight: 300;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoRegularWoff2}) format('woff2'),
      url(${RobotoRegularWoff}) format('woff'),
      url(${RobotoRegularTtf}) format('ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoMediumWoff2}) format('woff2'),
      url(${RobotoMediumWoff}) format('woff'),
      url(${RobotoMediumTtf}) format('ttf');
    font-weight: 500;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoBoldWoff2}) format('woff2'),
      url(${RobotoBoldWoff}) format('woff'), url(${RobotoBoldTtf}) format('ttf');
    font-weight: 700;
  }
`;
