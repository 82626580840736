import client from '../client';

const resource = {
  subscribe: (data, token) => client.post(
    `api/newsletter`,
    data,
    {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}` 
        }
    }),
};

export default resource;