import client from '../client';

const resource = {
  login: (username, password) => client.post(`api/login_check`, { username, password }),
  connectAsClient: (clientId, token) => client.post(`api/connect-as-client`, { clientId }, { headers: { Authorization: `Bearer ${token}` } }),
  requestReset: (data) => client.post(`api/reset-password`, data, { headers: { 'Content-Type': 'multipart/form-data'} }),
  reset: (token, data) => client.post(`api/reset-password/reset/${token}`, data, { headers: { 'Content-Type': 'multipart/form-data'} }),
  checkResetLink: (token) => client.post(`api/reset-password/reset/${token}/check`, {}, { headers: { 'Content-Type': 'multipart/form-data'} }),
  accountActivation: (token, data) => client.post(`api/account-activation/${token}`, data, { headers: { 'Content-Type': 'multipart/form-data'} }),
  checkAccountActivationLink: (token) => client.post(`api/account-activation/${token}/check`, {}, { headers: { 'Content-Type': 'multipart/form-data'} })
};

export default resource;