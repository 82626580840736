import client from '../client';

const resource = {
  sendRequest: (token, data) => client.post(
    `api/profile-modification`,
    data,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
  ),
};

export default resource;