const AppReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN': {
            const { token, user } = action.payload;
            return {
                ...state,
                token,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                token: undefined,
                user: undefined,
                account: undefined
            };
        }
        case 'ACCOUNT_FILTER': {
            const { account } = action.payload;
            return {
                ...state,
                account,
            };
        }
        case 'ACCOUNT_RESET': {
            return {
                ...state,
                account: undefined
            };
        }
        case 'NOTIFICATION_PUSH': {
            const { notification } = action.payload;
            return {
                ...state,
                notification
            };
        }
        case 'NOTIFICATION_CLEAR': {
            return {
                ...state,
                notification: undefined
            };
        }
        case 'CONNECTIVITY_CHANGE': {
            const { connected } = action.payload;
            return {
                ...state,
                connected
            };
        }
        default: {
            return state;
        }
    }
};

export default AppReducer;