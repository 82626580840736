import client from '../client';

const resource = {
  get: (token) => client.get(`api/advisor`, { headers: { Authorization: `Bearer ${token}` } }),
  notify: (token) => client.post(
    `api/wish-to-be-called`,
    {},
    {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}` 
        }
    }),
};

export default resource;