import client from '../client';

const resource = {
    getPublicKey: (token) => client.get(`api/push-subscription/server-key`, { headers: { Authorization: `Bearer ${token}` } }),
    register: (token, data) => client.post(
        `api/push-subscription/register`,
        data,
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
      )
  };
  
  export default resource;