import { createContext, useEffect, useReducer } from "react";
import AppReducer from "reducers/app-reducer";

const initialState = {
    token: undefined,
    user: undefined,
    account: undefined,
    notification: undefined,
    connected: navigator.onLine
};

export const AppContext = createContext(initialState);

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState, () => {
        const backup = sessionStorage.getItem('backup');
        return backup ? { ...initialState, ...JSON.parse(backup) } : initialState;
    });

    useEffect(() => {
        const { token, user, account, notification } = state;
        sessionStorage.setItem('backup', JSON.stringify({ token, user, account, notification }));
    }, [state]);

    return (
        <AppContext.Provider
            value={{
                token: state.token,
                user: state.user,
                account: state.account,
                notification: state.notification,
                connected: state.connected,
                dispatch
            }}
        >
        { children }
        </AppContext.Provider>
    );
}

export default AppProvider;