import { DateTime } from 'luxon';
import client from '../client';

const resource = {
  all: (params, token) => client.get(`api/funds`, { params, headers: { Authorization: `Bearer ${token}` } }),
  get: (fund, token) => client.get(`api/funds/${fund}`, { headers: { Authorization: `Bearer ${token}` } }),
  filters: token => client.get(`api/funds/filters`, { headers: { Authorization: `Bearer ${token}` } }),
  download: (filepath, token) => client.get(`api/funds/${encodeURIComponent(filepath)}/download`, { headers: { Authorization: `Bearer ${token}` } }),
  export: (params, token) => client.get(`api/funds/export`, { params, responseType: 'blob', headers: { Authorization: `Bearer ${token}` } }),
  portfolioAllocation: (fund, token) => client.get(`api/funds/portfolio-allocation/${fund}`, { headers: { Authorization: `Bearer ${token}` } }),
  valuationBenchmark: (fund, params, token) => {
    let { startDate, endDate } = params;
    if (!startDate && !endDate) {
      startDate = DateTime.now().minus({ year: 1 }).endOf('year').startOf('week').plus({ days: 4 }).toISODate();
      endDate   = DateTime.now().minus({ weeks: 1 }).startOf('week').plus({ days: 5 }).toISODate();

      if (startDate.year !== DateTime.now().minus({ year: 1 }).year) {
        startDate = DateTime.fromISO(startDate).minus({ days: 7 }).toISODate();
      }
    }

    params = { start_date: startDate, end_date: endDate };
  
    return client.get(`api/funds/valuation-benchmark/${fund}`, { params, headers: { Authorization: `Bearer ${token}` } })
  }
};

export default resource;