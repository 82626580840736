export const colors = {
  green: '#6b8544',
  blue: '#2f3e48',
  gray: '#ccc'
};

export const fonts = {
  barlowCondensed: "'Barlow Condensed', sans-serif",
  barlow: "'Barlow', sans-serif",
  roboto: "'Roboto', sans-serif",
  firaSans: "'Fira Sans', sans-serif",
};
